<template>
  <v-app class="app-box">
    <v-banner
      single-line
      height="742"
      class="banner"
    >
      <img class="logo" src="@/assets/login/logo_bai.png" alt="" @click="$router.push('/')">
      <div class="banner-box">
        <div class="zs">
          <img class="img-01" src="https://h5.ophyer.cn/official_website/page/bigitalMan/banner-zs-01.png" alt="">
          <img class="img-02" src="https://h5.ophyer.cn/official_website/page/bigitalMan/banner-zs-02.png" alt="">
        </div>
        <div class="ren">
          <img class="wz" src="https://h5.ophyer.cn/official_website/page/bigitalMan/banner-wz.png" alt="">
          <img class="ren-01" src="https://h5.ophyer.cn/official_website/page/bigitalMan/banner-ren-01.png" alt="">
          <img class="ren-02" src="https://h5.ophyer.cn/official_website/page/bigitalMan/banner-ren-02.png" alt="">
          <img class="ren-03" src="https://h5.ophyer.cn/official_website/page/bigitalMan/banner-ren-03.png" alt="">
        </div>
      </div>
      <!-- <v-img
        src="https://h5.ophyer.cn/official_website/page/bigitalMan/bigitalMan-banner-01.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      > -->
        <div class="banner-text">
          <div class="tit">
            <p>一站式数字人技术</p>
            <p>One-stop digital human technology</p>
          </div>
          <p class="text">飞天云动拥有从建模到角色驱动的完整技术方案 为用户提供一站式服务</p>
        </div>
      <!-- </v-img> -->
    </v-banner>

    <!-- 数字人系统架构 -->
    <v-card class="app-warp warp1 animate__animated animate__fadeInUp wow" data-wow-duration="1s" data-wow-offset="50" :flat="true">
      <div class="public-titles">
        <p class="tit-z">数字人系统架构</p>
        <p class="tit-e">System architecture</p>
      </div>
      <div class="con">
        <img src="https://h5.ophyer.cn/official_website/page/bigitalMan/warp01-img-01.png" />
      </div>
    </v-card>

    <!-- 多终端适配 -->
    <v-card class="app-warp warp2 animate__animated animate__fadeInUp wow" data-wow-duration="1s" :flat="true">
      <div class="public-titles">
        <p class="tit-z">多终端适配</p>
        <p class="tit-e">Multi-terminal adaptation</p>
      </div>
      <div class="warp-box">

        <div v-for="(item,index) in warp2Content" :key="index" class="box">
          <div :style="{backgroundImage: 'url('+item.bg+')'}"></div>
          <p>{{ item.text1 }}</p>
          <p>{{ item.text2 }}</p>
        </div>
      </div>
    </v-card>

    <!-- 功能介绍 -->
    <v-card class="app-warp warp3 animate__animated animate__fadeInUp wow" data-wow-duration="1s">
      <div class="public-titles">
        <p class="tit-z">功能介绍</p>
        <p class="tit-e">Function introduction</p>
      </div>
      <div class="warp-box">
        <div class="left">
          <img :src="warp3Content.list[warp3Content.hover].img" alt="">
        </div>
        <div class="right">
          <div v-for="(item, index) in warp3Content.list" :key="index" class="box" @mouseenter="warp3Content.hover = index">
            <p>{{ item.title }}</p>
            <div :class="[warp3Content.hover === index ? '' : 'text-hidden']"> {{ item.text }} </div>
          </div>
        </div>
      </div>
    </v-card>

    <!-- 应用场景 -->
    <v-card class="education-warp warp4 animate__animated animate__fadeInUp wow" data-wow-duration="1s" :flat="true">
      <div class="public-titles">
        <p class="tit-z">应用场景</p>
        <p class="tit-e">Application scenario</p>
      </div>
      <div class="swiper-box">
        <swiper ref="mySwiper" :options="swiperOptions" v-if="lunbo.length">
          <swiper-slide
            v-for="item in lunbo"
            :key="item.id"
            style="width:100%;height:100%"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+item.img+')'}">
              <div class="bottom-box">
                <p class="name">{{ item.title }}</p>
                <p class="text">{{ item.text }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- 左箭头 -->
        <div ref="swiperPrev" class="swiper-button-prev" style="margin-left: -72px;">
          <img src="https://h5.ophyer.cn/official_website/page/bigitalMan/icon-right.png" alt="">
        </div>
        <!-- 右箭头。-->
        <div ref="swiperNext" class="swiper-button-next" style="margin-right: -72px;">
          <img src="https://h5.ophyer.cn/official_website/page/bigitalMan/icon-left.png" alt="">
        </div>
      </div>
    </v-card>

    <!-- 底部 -->
    <OfficialWebsiteFooter />
    <!-- 右侧悬浮 -->
    <RightSuspend />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "education",
  components: {
    Swiper,
    SwiperSlide,
    OfficialWebsiteFooter: () => import('@/components/common/officialWebsiteFooter.vue'),
    RightSuspend: () => import('@/components/common/rightSuspend.vue'),
  },
  computed: {
    ...mapState(['officialWebsite'])
  },
  data() {
    return {
      imgLoad: false,
      swiperOptions: {
        slidesPerView: 4,
        loop: true,
        autoplay : false,
          prevButton: '.swiper-button-prev',
          nextButton: '.swiper-button-next',
        on: {}
      },
      warp2Content: [
        {
          bg: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp2-bg-01.png',
          text1: '移动端',
          text2: 'PHONE'
        },
        {
          bg: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp2-bg-02.png',
          text1: '电脑端',
          text2: 'WEB'
        },
        {
          bg: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp2-bg-03.png',
          text1: '移动大屏',
          text2: 'SCREEN'
        }
      ],
      warp3Content: {
        hover: 0,
        list: [
          {
            id: 0,
            img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp03-img-01.png',
            title: '多种风格的数字人',
            text: '超写实角色、拟真人角色、卡通风格。通过一张照片就可以秒级别生成各类风格的角色效果，可广泛使用在多种平台上'
          },
          {
            id: 1,
            img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp03-img-02.png',
            title: '灵动逼真的交互表现',
            text: '支持真人面部驱动、肢体驱动、手指驱动、语音/文本驱动，支持动作、表情、语音内容等感知深度匹配'
          },
          {
            id: 2,
            img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp03-img-03.png',
            title: '成熟全面的产品方案',
            text: '提供全面的多端SDK，也提供完善的软件方案，针对不同的场景需求，快速灵活引入虚拟人能力'
          }
        ]
      },
      lunbo: [
        {
          id: 0,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img01.png',
          title: '元宇宙',
          text: '虚拟空间'
        },
        {
          id: 1,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img02.png',
          title: '汽车',
          text: '虚拟助手'
        },
        {
          id: 2,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img03.png',
          title: '传媒',
          text: '虚拟主持人、虚拟主播'
        },
        {
          id: 3,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img04.png',
          title: '文旅',
          text: '虚拟导游、虚拟讲解员'
        },
        {
          id: 4,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img05.png',
          title: '金融',
          text: '智能客服'
        },
        {
          id: 5,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img06.png',
          title: '医疗',
          text: '心理医生、家庭医生'
        },
        {
          id: 6,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img07.png',
          title: '零售',
          text: '虚拟售货员'
        },
        {
          id: 7,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img08.png',
          title: '教育',
          text: '虚拟教师'
        }
      ],
   
    }
  },
  mounted() {
    new this.$wow.WOW().init()
  },
  methods: { 
    handleLoad (){
      this.imgLoad = true;
    },
    open(url) {
      window.open(url, '_blank')
    },

  }
}
</script>

<style lang="scss">
html,body {
  background: #0B0C12!important;
}
</style>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
  color: #969799 !important;
}
.app-box {
  background: #0B0C12;
  min-width: 1376px;
}
.v-card {
  background: #0B0C12;
}
ul {
  margin: 0;
  padding: 0;
}

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text {
    background: #0B0C12;
    padding: 0 !important;
    height: 100%;
  }
  .logo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 160px !important;
    cursor: pointer;
    z-index: 9;
  }
  .banner-text {
    height: 100%;
    width: 430px;
    margin-left: 15%;
    position: absolute;
    top: 50%;

    .tit {
      p:nth-of-type(1) {
        height: 36px;
        font-size: 52px;
        line-height: 36px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
      }
      p:nth-of-type(2) {
        height: 40px;
        letter-spacing: -2px;
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        color: rgba(255, 255, 255, 0.1);
        // mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 0, 255, 0)), to(#d8d5d5));
        margin-left: -91px;
      }
    }
    .text {
      margin-top: 20px;
      font-size: 16px;
      width: 357px;
      white-space: pre-wrap;
      margin-left: 62px;
      text-align: end;
      line-height: 24px;
    }
    p {
      color: #FFFFFF;
      margin: 0;
    }
  }

  .banner-box {
    height: 100%;
    width: 100%;
    background-image: url('https://h5.ophyer.cn/official_website/page/bigitalMan/banner-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .zs {
      width: 997px;
      position: absolute;
      right: -100px;
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
      .img-01 {
        top: 102px;
        
      }
      .img-02 {
        animation: movedowm 5s infinite;
      }
    }
    .ren {
      width: 712px;
      height: 650px;
      position: absolute;
      right: 140px;
      bottom: 0;
      img {
        position: absolute;
      }
      .wz {
        width: 100%;
        height: 443px;
      }
      .wz {
        position: absolute;
        z-index: 9;
        bottom: 30px;
      }
      .ren-01 {
        z-index: 9;
        left: 50px;
        animation: movelengthways 5s infinite;

      }
      .ren-02 {
        z-index: 1;
        top: -30px;
        left: 30%;
        // transform: translate(-50%, 0);
        animation: movedowm 5s infinite;

      }
      .ren-03 {
        z-index: 9;
        top: -30px;
        right: 30px;
        animation: movelengthways 5s infinite;

      }
    }
  }

  @keyframes movedowm {
    0% {
      transform: translateY(0px);
    }

    46% {
      transform: translateY(12px);
    }

    54% {
      transform: translateY(12px);
    }

    95% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(0px);
    }
  }
  @keyframes movelengthways {
    0% {
      transform: translateY(0px);
    }

    46% {
      transform: translateY(-12px);
    }

    54% {
      transform: translateY(-12px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}
@media screen and (min-width: 1620px) {
  .banner-text {
    margin-left: 13%;
  }
  .zs {
    right: 8% !important;
  }
  .ren {
    right: 20% !important;
  }
}


.public-titles {
  height: 100px;
  margin: 100px auto 0px;
  position: relative;
  text-align: center;
  padding-top: 0;
  margin-bottom: 45px;
  .tit-z {
    height: 36px;
    font-size: 36px;
    line-height: 36px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #FFFFFF;
  }
  .tit-e {
    height: 40px;
    font-size: 48px;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
    mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.4)));
  }
  p {
    margin-bottom: 0;
  }

}

.app-warp {
  width: 100%;
}

.warp1 {
  text-align: center;
  img {
    height: 370px;
  }
}

.warp2 {
  .warp-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .box {
      width: 400px;
      height: 480px;
      padding: 32px 24px;
      background: #2F3035;
      position: relative;
      div {
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
      }
      p {
        font-size: 24px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        margin: 0;
        &:nth-of-type(1) {
          margin-bottom: 10px;
          color: #FFFFFF;
        }
        &:nth-of-type(2) {
          color: rgba(255,255,255,0.1);
        }
      }
      &:nth-of-type(2) {
        background: #24252A;
      }
    }
  }
}

.warp3 {
  .warp-box {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 579px;
    }
    .right {
      height: 400px;
      margin-left: 40px;
      .box {
        width: 552px;
        background: rgba(255,255,255,0.1);
        border-radius: 10px 10px 10px 10px;
        padding: 34px 36px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        cursor: pointer;
        p {
          font-size: 24px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 35px;
          margin-bottom: 0;
        }
        div {
          max-height: 250px;
          transition: all 500ms;
          overflow: hidden;
          margin-top: 10px;
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
        }
        .text-hidden {
          max-height: 0;
          margin-top: 0;
          transition: all 250ms;
        }
      }
    }
  }
}

.warp4 {
  margin-bottom: 100px;
  .swiper-box {
    width: 1228px;
    height: 480px;
    margin: 0 auto;
    position: relative;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .swiper-button-next, .swiper-button-prev {
    width: 54px;
    height: 54px;
    border-radius: 54px;
    background: #3C3D41;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 50%);
    top: 50%;
    img {
      width: 20px;
    }
    &::after {
      display: none;
    }
  }
  .swiper-slide {
    height: 480px;
    width: 291px !important;
    // margin: 0 9px;
    margin-right: 17px;
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
  }
  .box-img {
    width: 100%;
    // aspect-ratio: 1/1.64;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% !important;
    border-radius: 7px;
    position: relative;
    cursor: pointer;
    background: rgba(0,0,0,0.5);
    
  }
  .bottom-box {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    border-radius: 10px 10px 10px 10px;
    padding: 24px 30px;
    transition: background 0.5s linear;
    p {
      margin: 0;
      text-align: start;
    }
    .name {
      font-size: 30px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FFFFFF;
    }
    .text {
      margin-top: 10px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }
    &:hover {
      background: rgba(0,0,0,0.2);
    }
  }
}


</style>